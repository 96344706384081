.proceedBtn {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	width: 142px;
	height: 66px;
	justify-content: center;
	margin: 30px 0px;
	background: linear-gradient(90.56deg, #f21623 -27.33%, #ea4b54 137.21%);
	border-radius: 50px;
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 24px;
	line-height: 156.5%;
	color: #ffffff;
	border: none;
}

.loadingButton {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	width: 142px;
	height: 66px;
	justify-content: center;
	margin: 30px 0px;
	border-radius: 50px;
background: transparent;
border: 1px solid #f21623;
	
	
}


.react-tel-input .form-control {
	border: 1px solid #C8C8C8 !important;
	border-radius: 60px !important;
	width: 100%;
	width: -moz-available;
	/* WebKit-based browsers will ignore this. */
	width: -webkit-fill-available;
	/* Mozilla-based browsers will ignore this. */
	width: fill-available;
}

.react-tel-input .flag-dropdown {
	position: absolute;
	top: 0;
	bottom: 0;
	padding: 0;
	background-color: #f5f5f500;
	border: 1px solid #cacaca;
	border-radius: 50px 0 0 50px;


}

.react-tel-input .flag-dropdown:hover {
	background-color: transparent !important;
	background: #fff0;
	border-radius: 50px 0 0 50px;
	border: 1px solid #cacaca;

}

.react-tel-input .selected-flag {
	border: none !;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
	background-color: #fff0;
}

.react-tel-input .flag-dropdown.open .selected-flag {
	background: #fff0;
}

.react-tel-input .flag-dropdown.open .selected-flag:hover {
	background: #fff0;
}

.react-tel-input .flag-dropdown.open {
	z-index: 2;
	background: #fff0;
	border-radius: 50px 0px 0px 50px;
}

.react-tel-input .flag-dropdown.open:hover {
	z-index: 2;
	background: #fff0;
	border-radius: 50px 0 0 50px;
}

.joinNowinput input {
	border-radius: 8px !important;
	height: 48px !important;
	background: #ffffff !important;
	border: 1px solid #C8C8C8;
	border-radius: 50px;
	outline: none !important;
	padding: 1rem !important;
}



.joinNow input:focus,
.joinNowinput input:focus {
	outline: none !important;
}

.joinNow input {
	border-radius: 8px;
	height: 48px;
	background: #ffffff;
	border: none;
	border: 1px solid #C8C8C8;
	border-radius: 50px;
	padding: 1rem;
}

.congratulations .header {
	padding-bottom: 1rem;
	border-bottom: 1px solid #888585;
}

.react-tel-input .form-control {

	padding-left: 60px !important;
	box-shadow: none !important;
}

.react-tel-input .selected-flag {
	outline: none;
	position: relative;
	width: 50px;
	height: 3rem !important;
	display: flex;
	padding: 0 0 0 0px;
	border-radius: 3px 0 0 3px;
	align-items: center !important;
	justify-content: center;
}

.congratulations .title {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 24px;
	line-height: 156.5%;
	/* identical to box height, or 38px */

	font-feature-settings: "tnum" on, "lnum" on;

	color: #000000;
}

.congratulations .brief {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 156.5%;
	/* or 28px */

	color: #000000;
}

.congratulations label {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 156.5%;
	/* or 28px */
	margin-bottom: 0.4rem;
	color: #000000;
}

.proceedBtn:disabled {
	background: linear-gradient(90.56deg, #F21623 -27.33%, #EA4B54 137.21%);
	opacity: 0.2;
}

@media screen and (max-width: 600px) {
	.congratulations .title {
		font-size: 20px;
	}

	.congratulations .brief {
		font-size: 14px;
	}

	.proceedBtn,.loadingButton {
		font-size: 14px;
		width: 140px;
		height: 42px;
	}
}