.Footer {
	background-color: black;
	padding: 1.5rem;
	color: #fff;
	font-size: 14px;
}
.footer_dot {
    width: 5px;
    background-color: white;
    height: 5px;
    border-radius: 50%;
	margin-right: 10px;
}
@media screen and (max-width: 600px) {
.privacyPolicy{
	margin-top:0.5rem ;
}

.Footer {
	background-color: black;
	padding:1.2rem;
	color: #fff;
	font-size: 14px;
}
}