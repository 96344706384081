.OfferDescription {
	text-align: center;
	font-family: 'Avenir LT Std';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 156.5%;
	color: #212121;
	margin-bottom: 8px;
}

.LatestPrice {
	font-size: 24px;
	font-weight: 750 !important;
}

.PreviousPrice {
	position: relative;
}

.crossLine {
	position: absolute;
	left: 0px
}

.advertisementWrapper {
	margin-top: 60px;
}

.Advertisement {
	border: 1px solid rgba(242, 22, 35, 1);
	border-radius: 5px;
}

.OfferNeverBefore {
	font-family: 'Avenir LT Std';
	font-style: normal;
	font-weight: 750;

	font-size: 32px;
	line-height: 156.5%;
	/* identical to box height, or 50px */

	font-feature-settings: 'tnum' on, 'lnum' on;

	/* test gradient */

	background: linear-gradient(90.56deg, #F21623 -27.33%, #EA4B54 137.21%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}

.Advertisement .title {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 300;
	font-size: 46px;
	line-height: 120.5%;
	color: #626262;
}

.timeCountdown {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 22.4602px;
	line-height: 156.5%;

	/* or 35px */
	font-feature-settings: "tnum" on, "lnum" on;

	/* Black */
	color: #212121;
	text-shadow: 0px 0px #00000000;
}

.pink {
	font-family: "Avenir LT Std" !important;
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
	color: #f21623;
}

.Advertisement .brief {
	font-family: 'Avenir LT Std';
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 156.5%;
	/* or 28px */

	font-feature-settings: 'tnum' on, 'lnum' on;

	/* Black */

	color: #212121;

	opacity: 0.7;
}

.TimeDatatype {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 300;
	font-size: 16.8451px;
	line-height: 156.5%;
	/* or 26px */

	/* Black */

	color: #212121;
}

.remain {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 300;
	font-size: 17px;
	line-height: 21px;
	/* identical to box height */

	text-align: center;

	/* Black */

	color: #212121;
}

.advertisementCard {
	border-radius: 50px;
	width: 520px !important;
	background: #FAEFE6;
}

@media only screen and (max-width: 991px) {
	.advertisementWrapper {
		margin-top: 48px;
	}



	.Advertisement .title {
		font-weight: 300;
		font-size: 24px;
		text-align: center;
	}

	.Advertisement .brief {
		font-size: 14px;
	}

	.pink {
		font-weight: 300;
		font-size: 30px;
	}

	.timeCountdown {
		font-size: 25px;
	}

	.TimeDatatype {
		font-size: 14px;
	}

	.OfferNeverBefore {
		font-size: 20px;
		line-height: 156.5%;
	}

	.OfferNeverBefore div {
		margin: 8px 0px;

	}

}

@media screen and (max-width: 768px) {
	.advertisementWrapper {
		margin-top: 48px;
	}

	.Advertisement .title {
		font-weight: 300;
		font-size: 24px;
		text-align: center;
	}

	.Advertisement .brief {
		font-size: 14px;
	}

	.pink {
		font-weight: 300;
		font-size: 24px;
	}

	.timeCountdown {
		font-size: 20px;
	}

	.TimeDatatype {
		font-size: 14px;
	}

	.OfferNeverBefore {
		font-size: 20px;
		line-height: 156.5%;
	}

	.OfferNeverBefore div {
		margin: 8px 0px;

	}

	.remain {
		font-family: 'Avenir LT Std';
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 17px;
		text-align: center;
		color: #212121;

	}
}


@media screen and (max-width: 600px) {
	.advertisementWrapper {
		margin-top: 48px;
	}



	.OfferDescription {
		justify-content: center;
		text-align: center;
		font-family: 'Avenir LT Std';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 185.5%;
		color: #212121;
		margin-bottom: 10px;
padding: 0 9px;
	}


	.Advertisement .title {
		font-weight: 300;
		font-size: 24px;
		text-align: center;
	}

	.Advertisement .brief {
		font-size: 14px;
	}

	.pink {
		font-weight: 300;
		font-size: 24px;
	}

	.timeCountdown {
		font-size: 20px;
	}

	.TimeDatatype {
		font-size: 14px;
	}

	.OfferNeverBefore {
		font-size: 20px;
		line-height: 156.5%;
	}

	.OfferNeverBefore div {
		margin: 8px 0px;
	}

	.remain {
		font-family: 'Avenir LT Std';
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 17px;
		text-align: center;
		color: #212121;

	}

	.advertisementCard {
		border-radius: 20px;
		background: #FAEFE6;
	}
}