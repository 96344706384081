.HourDayComparism {
	margin-top: 60px;
}

.hourDaysCmp .left {
	background-color: #fdece0;
	/* padding: 2rem; */
}
.hourDaysCmp .right {
	background-color: #d0e7ff;
	padding-left: 4rem;
}
.outerbox {
	width: 124px;
	height: 124px;
	border-radius: 50%;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}
.innerbox {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background: #fdf8f4;
	display: flex;
	align-items: center;
	justify-content: center;
}
.pros {
	/* Light/Regular */
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 156.5%;
	margin-left: 1rem;
	/* or 28px */
	display: flex;
	align-items: center;
	opacity: 0.8;
	/* Black */
	color: #212121;
}
.yogaImage {
	left: 45%;
	top: 48%;
	transform: translate(-50%, -50%);
}
@media screen and (max-width: 600px) {
	.HourDayComparism {
		margin-top:48px;
	}
}
