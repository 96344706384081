.whyred {
	margin-top: 60px;
}
.whyBox {
	width: 22%;
	height: max-content;
	position: relative;
}
.whyRedTittle{
	margin-bottom: 24px;
}
.whyBox .left {
	/* position: absolute; */
	position: relative;
	left: 40%;
}
.whyBox .right {
	position: relative;
	left: -20%;
}
.whyBox .line {
	background-color: #212121;
	width: 65%;
	height: 1px !important;
}
.whyBox .solidCircle {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: black;
}

.whyBox .whyDescription {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 300;
	font-size: 14.1478px;
	line-height: 156.5%;

	/* or 22px */
	display: flex;
	align-items: center;

	/* Black */
	color: #212121;
}

.whyredHeader {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 32px;
	line-height: 156.5%;

	/* identical to box height, or 50px */
	display: flex;
	align-items: center;
	text-align: center;
	font-feature-settings: "tnum" on, "lnum" on;

	/* Black */
	color: #212121;
}
.whyTitle {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 14.1478px;
	line-height: 0 !important;

	/* or 22px */
	display: flex;
	align-items: center;
	font-feature-settings: "tnum" on, "lnum" on;

	/* test gradient */
	background: linear-gradient(90.56deg, #f21623 -27.33%, #ea4b54 137.21%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}
.whyDescription {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 300;
	font-size: 14.1478px;
	line-height: 156.5%;

	/* or 22px */
	display: flex;
	align-items: center;

	/* Black */
	color: #212121;
}
.evenlyItem {
	/* d-flex aling-items-center justify-content-evenly py-5 */
	display: flex;
	align-items: flex-start;
	justify-content: space-evenly;
	/* padding: 3rem 0; */
}
.betweenItem {
	/* d-flex aling-items-center justify-content-evenly py-5 */
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 3rem;
}
.meditaionImage {
	left: 49%;
	top: 45%;

	transform: translate(-50%, -50%);
}

@media screen and (max-width: 600px) {
	.whyRedTittle{
		margin-bottom: 16px;
	}
	.whyred {
		margin-top:48px;
	}
	.whyredHeader {
		font-weight: 750;
		font-size: 20px;
	}
	.whyBox {
		width: 20%;
	}
	.whyBox .whyDescription {
		font-weight: 600;
		font-size: 6px;
	}
	.whyBox .whyTitle {
		font-weight: 750;
		font-size: 7px;
		white-space: nowrap;
	}
	.betweenItem {
		padding: 1.5rem 0;
	}
}
