.joinAd {
	font-weight: 750;
	font-size: 14px;
}
.PreviousPriceBanner{
position: relative;
}
.crossLinelBanner {
    left: 0;
    top: 0px;
    position: absolute;
}

.joinAd {
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	/* height: 72px; */
	/* padding: 10px 24px;
	gap: 8px;
	isolation: isolate;
	background: linear-gradient(
		270.78deg,
		#0481ff -3.68%,
		#535bff 12.85%,
		#fc1255 102.15%
	);
	border-radius: 50px;
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 24px;
	line-height: 156.5%;


	display: flex;
	align-items: center;
	font-feature-settings: "tnum" on, "lnum" on;


	color: #ffffff; */
}

.strikethrough {
	position: relative;
}

.strikethrough:before {
	position: absolute;
	content: "";
	/* background-color: #fc1255; */

	left: 0;
	top: 40%;
	right: 0;
	border-top: 3px solid;
	border-color: #1b1b1b;

	-webkit-transform: rotate(-32deg);
	-moz-transform: rotate(-32deg);
	-ms-transform: rotate(-32deg);
	-o-transform: rotate(-32deg);
	transform: rotate(-32deg);
}

.NeverBefore {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 32px;
	line-height: 156.5%;
	/* identical to box height, or 50px */

	font-feature-settings: "tnum" on, "lnum" on;

	/* test gradient */
	color: #f21623;
	/* background: linear-gradient(90.56deg, #f21623 -27.33%, #ea4b54 137.21%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent; */
	text-shadow: 0px 0px #00000000;
}

.firstMonthOff {
	width: 80%;
	margin-top: 8px;
	margin-bottom: 8px;
	font-family: 'Avenir LT Std' !important;
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 185.5%;
	color: #212121;
}


.OfferDescription{
	font-family: 'Avenir LT Std';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 156.5%;
/* or 28px */


/* Black */

color: #212121;
}

.OfferDescriptionResp{
	display: none !important;
}


@media screen and (max-width: 991px) {
	.firstMonthOff {
		font-size: 14px;
	}
	.crossLinelBanner {
		left: 0;
		top: -3px;
		position: absolute;
	}
}

@media screen and (max-width: 500px) {
	.joinAd {
		font-weight: 750;
		font-size: 20px;
		/* height: 40px; */
	}

	.NeverBefore {
		font-size: 20px;
	}

	.banner .featureBrief {
		margin-top: 24px !important;
	}

	.firstMonthOff {
		width: 92%;
		margin-top: 8px;
		margin-bottom: 10px;
		font-family: 'Avenir LT Std' !important;
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 185.5%;
		color: #212121;
	}
	.priceOffer{
		display: none;
		padding:0px 2rem !important;
		margin-top: 0px !important;
	}
	.OfferDescriptionResp{
		display: block !important;
	}
}