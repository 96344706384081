.TimesHeader {

    -webkit-display: flex;
    -moz-display: flex;
    -ms-display: flex;
    -o-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    /* ie 10 */
    display: -webkit-flex;
    display: flex;
}

.TimesHeader div {
    margin-left: 1.5rem;

}

.TimesHeader div:first-child {
    margin-left: 0rem;

}

.TimesHeader div p {
    margin-bottom: 0px;
    margin-left: 8px;


    font-family: 'Avenir LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 156.5%;
    /* identical to box height, or 22px */

    font-feature-settings: 'tnum' on, 'lnum' on;

    /* Black */

    color: #212121;
}

.ladies-only {
    margin: 1rem 1rem 0 0rem;
    font-size: 13px;
}

.ladies-only svg {
    background-color: #FEA3AC;
    color: #FEA3AC;
    border-radius: 1px;
    margin-right: 3px;
}

.TimesScheduleTittle {
    text-align: center;
    font-family: 'Avenir LT Std';
    font-style: normal;
    font-weight: 750;
    margin-bottom: 18px;
    font-size: 24px;
    line-height: 156.5%;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #212121;
}

.schedules h5,
.schedules p {
    margin-bottom: 5px;
}

.yogaGirl {
    background-image: url("/public/assets/yogaGirl.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;

    height: 423px
}

.form-floating>.form-select {

    padding: 0px;
    padding-left: 20px;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.badge {
    font-family: 'Avenir LT Std';
    font-style: normal;
    font-weight: 300 !important;
    line-height: 156.5%;
    color: #000000 !important;
    display: inline-block;
    padding: .35em .65em;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;

    font-size: 18px !important;
    /* or 28px */


    color: #000000;
}

.timing-badge {
    background-color: #dcdcdc;
    color: #000;
    padding: .75rem 1.1rem !important;
    font-size: 13px;
    font-weight: 400;
    margin: .5rem .25rem;
}



.timeSection {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 0px;
    gap: 8px;
}

.ladiesTimes {
    background-color: #FEA3AC !important;

}

.schedules h5 {
    font-family: 'Avenir LT Std';
    font-style: normal;
    font-weight: 750;
    font-size: 18px;
    line-height: 156.5%;
    /* or 28px */

    font-feature-settings: 'tnum' on, 'lnum' on;

    /* grey */

    color: #4D4D4D;
}

.schedules p {
    font-family: 'Avenir LT Std';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 156.5%;
    /* or 28px */


    /* grey */

    color: #4D4D4D;

}

.ladiesTime {
    font-family: 'Avenir LT Std';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 156.5%;
    /* or 28px */


    color: #000000;
}


.ladiesTimeColor {
    background-color: #FEA3AC;
    width: 13px;
    height: 10px;
}

.MainTittleSection {
    width: 79.333333% !important
}

.timezone .timezone-select {
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.26);
    border-radius: 8px;
    width: 305px;
}

.css-1s2u09g-control {
    border-color: #EA4B54 !important;
    height: 43.96870040893555px;
}

.css-1pahdxg-control {

    border-color: #EA4B54 !important;
    height: 43.96870040893555px;
    box-shadow: none !important;
}

@media only screen and (max-width: 991px) {
    .timezone .timezone-select {
        width: 250px;
    }
}

@media screen and (max-width: 600px) {
    .timezone .timezone-select {
        width: 100%;
    }

    .schedules h5,
    .schedules p,
    .timeSection span {
        font-size: 14px !important;
    }

    .TimesHeader {
        margin-bottom: 16px;
        display: inline-block;
        -webkit-display: flex;
        -moz-display: flex;
        -ms-display: flex;
        -o-display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        /* ie 10 */
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        margin-right: 0.5rem;

    }



    .MainTittleSection {
        width: 100% !important;
    }

    .TimesHeader div img {
        width: 13px;
    }

    .TimesHeader div p {
        font-family: 'Avenir LT Std';
        font-style: normal;
        font-weight: 400;
        margin-left: 8px;
        font-size: 12px;
        line-height: 156.5%;
        /* identical to box height, or 19px */

        font-feature-settings: 'tnum' on, 'lnum' on;

        /* Black */

        color: #212121;
    }

    .tittleSection {
        display: block;
    }

    .timeSection {
        flex-wrap: wrap;
    }

    .timezone {
        /* margin-top: 20px;*/
        margin-bottom: 16px;
    }


    .horizontalLine {
        display: none;
    }

    .TimesScheduleTittle {
        text-align: center;
        margin-bottom: 16px;
    }

    .TimesHeader div {
        margin-left: 1rem;
    }
}

@media screen and (max-width: 478px) {
    .TimesHeader div {
        margin-left: 0rem;
        margin-right: 0.5rem;
        margin-top: 0.5rem;
    }
}

.css-1s2u09g-control svg {
    color: #EA4B54
}