.PrivacyPolicy{
    margin: 3rem 4rem;
    color:#444444

}

.PrivacyPolicy .header{
    display: flex;
    justify-content: center;
    align-items: center;
}

.PrivacyPolicy .policy{
    margin-top: 40px;
}

@media screen and (max-width: 776px) {
    .PrivacyPolicy{
        margin: 1.5rem 2rem;
    }
}