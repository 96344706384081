.sub-Tittle {
	font-family: 'Avenir LT Std';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 156.5%;
	text-align: center;
	color: #212121;
	opacity: 0.8;
	margin-top: 10px;
	margin-bottom: 60px;
}

.aboutBharatThakur {
	margin-top: 60px;
}

.aboutBharatThakur .title {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 32px;
	line-height: 156.5%;
height: 50px;

	/* identical to box height, or 50px */
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	font-feature-settings: "tnum" on, "lnum" on;
	margin-bottom: 24px;
	/* Black */
	color: #212121;
}

.aboutBharatThakur .description {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 140.5%;

	/* or 28px */
	font-feature-settings: "tnum" on, "lnum" on;

	/* Black */
	color: #212121;

	opacity: 0.7;
}

/* .aboutBharatThakur{
	height: 248px !important;
} */

@media screen and (max-width: 991px) {
	.aboutBharatThakur .description {
		margin-top: 24px;
		font-size: 16px;
	}

	.benifitBox>div {
		padding: 2rem 1rem;
		border: 1px solid rgba(242, 22, 35, 1);
		border-radius: 5px;
		height: 100%;
		/* width: fit-content; */
		width: 100%;
	}
}

@media screen and (max-width: 768px) {
	.aboutBharatThakur .description {
		margin-top: 24px;
		font-size: 14px;
	}
}

@media screen and (max-width: 600px) {
	.aboutBharatThakur {
		margin-top: 48px;
	}

	.FounderMessage {
		font-family: 'Avenir LT Std';
		font-style: normal;
		font-weight: 750;
		font-size: 20px;
		line-height: 156.5%;
		/* or 31px */


		text-align: center;
		font-feature-settings: 'tnum' on, 'lnum' on;

		/* Black */

		color: #212121;

	}

	.aboutBharatThakur .title {
		font-weight: 750;
		font-size: 20px;
		margin-bottom: 16px;
	}

	.aboutBharatThakur .description {
		margin-top: 24px;
		font-size: 14px;
	}
}