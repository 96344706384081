@font-face {
	font-family: 'Avenir LT Std';
	src:url('../public/fonts_Avenir/AvenirRegular.ttf')format("truetype")
	    /* url('../public/fonts_Avenir/AVENIR-MEDIUM.TTF'),
		url('../public/fonts_Avenir/AVENIR-BOOK.TTF') ,
		url('../public/fonts_Avenir/AVENIR_ROMAN_FONT.OTF')  */
}
h1{
	font-family: "Avenir LT Std", sans-serif !important;

}
body {
	font-family: "Avenir LT Std", sans-serif !important;
	background: #FDF8F4 !important;
}