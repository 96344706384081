.benifits {
	margin-top: 60px;
}
.benifitBox {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 1rem;
}
.benifitsHeader {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 32px;
	line-height: 156.5%;

	/* identical to box height, or 50px */
	display: flex;
	align-items: center;
	text-align: center;
	font-feature-settings: "tnum" on, "lnum" on;

	/* Black */
	color: #212121;
}
.benifitBox {
	flex: 1;
}
.benifitBox > div {
	padding: 2rem 1rem;
	border: 1px solid rgba(242, 22, 35, 1);
	border-radius: 5px;
	height: 100%;
}
.benifitBox .title {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 24px;
	line-height: 156.5%;

	/* or 38px */
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	font-feature-settings: "tnum" on, "lnum" on;

	/* Black */
	color: #212121;
}
.benifitBox .description {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 156.5%;

	/* or 28px */
	display: flex;
	align-items: center;

	/* Black */
	color: #212121;
}

@media screen and (max-width: 600px) {
	.benifits {
		margin-top:48px;
	}
	.benifitBox:first-child{
		padding: 0rem 1rem 1rem 1rem;
	}
	.benifitBox .title {
		font-weight: 750;
		font-size: 20px;
	}
	.benifitBox .description {
		font-size: 14px;
	}
	.benifitsHeader {
		font-weight: 750;
		font-size: 20px;
		margin-bottom: 16px;
	}
}
