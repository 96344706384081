.DownloadEbook {
	background: #fdece0;
	padding: 5rem;
	margin-top: 60px;
}

.DownloadEbook .title {
	/* Bold/H2 */
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 32px;
	line-height: 156.5%;

	/* identical to box height, or 50px */
	font-feature-settings: "tnum" on, "lnum" on;

	/* Black */
	color: #212121;
}

.DownloadEbook .downloadHelperText {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 156.5%;
	padding: 2rem 5rem;
	/* or 28px */
	text-align: center;

	/* Black */
	color: #212121;
}

.DownloadEbook .downloadDis {
	opacity: 0.6 !important;
	z-index: 200;
}

.DownloadEbook .download {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 24px;
	gap: 8px;
	/* border: 1px solid #ea4b54; */
	border-radius: 50px;
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 18px;
	line-height: 156.5%;
	background: linear-gradient(270.78deg,
			#0481ff -3.68%,
			#535bff 12.85%,
			#fc1255 102.15%);
	color: white;
	/* font-feature-settings: "tnum" on, "lnum" on;

	background: linear-gradient(90.56deg, #f21623 -27.33%, #ea4b54 137.21%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent; */
	z-index: 3;
}

.warning {
	margin-top: 1rem;
}

.warning div p {
	color: #CF3838;
	margin-bottom: 0px;
	margin-left: 5px;
}
.joinNow{
	/* margin-bottom: 42px; */
}
.EbookDescription {

width: 95%;
	font-family: 'Avenir LT Std';
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 156.5%;
	/* or 38px */

	font-feature-settings: 'tnum' on, 'lnum' on;

	/* Black */

	color: #212121;

}

.joinNow label {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 156.5%;
	/* or 28px */
	margin-bottom: 0.4rem;
	color: #000000;
}

.EmailInp:focus {
	outline: none;
	/* border-right: 0px !important; */
}

.EmailInp {
	display: block;
	position: relative;
	border-radius: 50px 0px 0px 50px;
	border: 1px solid #ffffff;
	padding: 9.5px 0;
	left: 1.7rem;
	z-index: 2;
	transition: all 1s ease-in-out;
	width: 0;
	font-size: 18px;
	background: white;
}

/* .transitionEffectInp {
	padding: 9.5px 24px;
	width: 25%;
} */
.pdfImage{
margin-top: 50px !important;
}

.GetBook{
	margin-top: 32px !important;
}
.disable{
	opacity: 0.5;
}
.Notdisable{
	opacity: 1;
}

@media screen and (max-width: 992px) {
	.GetBookWrapper{
		display: flex;
		justify-content: center;
	}
}

@media screen and (max-width: 600px) {
	.DownloadEbook {
		padding: 2rem;
		margin-top: 48px;
	}

	.DownloadEbook .title {
		font-weight: 700;
		font-size: 20px;
	}

	.DownloadEbook .downloadHelperText {
		padding: 1rem 0rem;
		font-size: 13px;
	}

	.DownloadEbook .download {
		font-size: 14px;
		padding: 10px 9px;
	}

	.EmailInp {
		font-size: 14px !important;
		padding: 9px 0px;
	}

	.transitionEffectInp {
		padding: 9px 24px;
		width: 60%;
	}
	.GetBook{
		margin-top: 32px !important;
	}
	.EbookDescription {
		font-size: 18px;
	}
	.pdfImage{
		margin-top: 10px !important;
	}

	.GetBookWrapper{
		display: flex;
		justify-content: center;
	}
}