.faq {
	margin-top: 60px;
	/* background-image: url('/public/assets/art1.png');
	background-position:right;
	top: 20%;
	background-repeat: no-repeat;
	background-size: contain; */
}
.accordion {
    --bs-accordion-bg: rgba(255, 255, 255, 0) !important;}
.faq .questions {
	padding: 1rem 5rem;
}
.faq .title {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 32px;
	line-height: 156.5%;
	margin-bottom: 24px;
	/* identical to box height, or 50px */
	text-align: center;
	font-feature-settings: "tnum" on, "lnum" on;

	/* Black */
	color: #212121;
}
.faq .accordion-button:not(.collapsed) {
	color: black !important;
	background-color: transparent !important;
	box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
		var(--bs-accordion-border-color);
}
.faq .accordion .accordion-button::after {
	content: "";
	background-image: url(/public/assets/accordion-open.svg);
	width: 35px;
	height: 35px;
	background-size: auto;
}
.faq .accordion .accordion-button:not(.collapsed)::after {
	background-image: url(/public/assets/minus.svg);
}
.viewAllBtn {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 24px;
	gap: 8px;
	border: 1px solid #f21623;
	border-radius: 50px;
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 18px;
	line-height: 156.5%;

	/* or 28px */
	font-feature-settings: "tnum" on, "lnum" on;
	cursor: pointer;
	color: #ea4b54;
	/* test gradient */
	/* background: linear-gradient(90.56deg, #f21623 -27.33%, #ea4b54 137.21%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent; */
	text-shadow: 0px 0px #00000000;
}
.accordion-button {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 300;
	font-size: 18px !important;
	line-height: 156.5%;
	/* or 28px */

	text-align: center;

	/* Black */

	color: #212121;
}
.accordion-body {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 300;
	font-size: 18px !important;
	line-height: 156.5%;
	/* or 28px */

	/* Black */

	color: #212121;
}
@media only screen and (max-width: 991px) {
	.faq .questions {
		padding: 1rem 0rem;
	}
}

@media only screen and (max-width: 768px) {
	.faq .questions {
		padding: 1rem 0rem;
	}
}

@media screen and (max-width: 600px) {
	.faq {
		margin-top:48px;
	}
	.faq .title {
		font-weight: 750;
		font-size: 20px;
		margin-bottom: 16px;
	}
	.faq .questions {
		padding: 0.5rem 0rem 1rem 0rem;
		}
	.viewAllBtn {
		font-size: 14px;
	}
	.accordion-button {
		font-size: 14px !important;
	}
	.accordion-body {
		font-size: 14px !important;
	}
}
