.TermsAndConditions{
    padding: 3rem 4rem;
color:#444444
}

.TermsAndConditions .header{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.TermsAndConditions .terms{
    margin-top: 40px;
}

.TermsAndConditions .terms ul{
    list-style-type: disc;
}





@media screen and (max-width: 776px) {
    .TermsAndConditions{
        padding: 1.5rem 2rem;
    }
}