.freeEbook {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 24px;
	line-height: 156.5%;

	/* identical to box height, or 38px */
	font-feature-settings: "tnum" on, "lnum" on;

	color: #000000;
}
.downloadInp {
	box-sizing: border-box;

	/* Auto layout */
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 24px;
	gap: 8px;

	width: 100%;
	height: 48px;

	background: #ffffff;
	border: 1px solid #e9e9e9;
	border-radius: 50px;
}
.downloadInp::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #ea4b54;
	opacity: 1; /* Firefox */
}

.downloadInp:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #ea4b54;
}

.downloadInp::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #ea4b54;
}
.downloadBtn {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 24px;
	gap: 8px;
	border: 1px solid #ea4b54;
	border-radius: 50px;
	color: #ea4b54;
	font-size: 18px;
}
.remindlater {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 24px;
	gap: 8px;
	/* border: 1px solid #ea4b54;
	border-radius: 50px; */
	color: #ea4b54;
}
.downloadNote {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 156.5%;
	/* or 28px */
	margin-bottom: 24px;

	color: #000000;
}
.modal-60w {
	width: 47% !important;
	max-width: none !important;
}
.locationName {
	font-size: 18px;
}
@media screen and (max-width: 600px) {
	.modal-60w {
		width: auto !important;
		max-width: none !important;
	}
	.downloadBtn {
		font-size: 14px;
	}
	.freeEbook {
		font-size: 20px;
	}
	.downloadNote {
		font-size: 14px;
	}
	.locationName {
		font-size: 14px;
	}
}
