.review {
	margin-top: 60px;
}
.reviewHeader {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 32px;
	line-height: 156.5%;
	margin-bottom: 24px;
	/* identical to box height, or 50px */
	display: flex;
	align-items: center;
	text-align: center;
	font-feature-settings: "tnum" on, "lnum" on;

	/* Black */
	color: #212121;
}
.review .reviewsVideo {
	display: flex;
	flex-wrap: wrap;
	/* overflow-x: scroll; */
	overflow: hidden;
}
.review .reviewsVideo > div {
	/* margin-bottom: 2rem; */
	/* aspect-ratio: 16 / 9;
	min-height: 235px;
	object-fit: contain; */
}
/* .react-player__preview */
.ReactPlayer video {
	border-radius: 8px;
	/* width: 100%;
	height: auto !important; */
}
.ReactPlayer .react-player__preview {
	/* border-radius: 8px; */
	padding: 24%;
	background-repeat: no-repeat !important;
	background-size: contain !important;
}
.reviewsVideo .ReactPlayer {
	/* background-color: #53505017; */
	/* border-radius: 8px; */
}
@media screen and (min-width: 1400px) {
	.review .reviewsVideo > div {
		/* aspect-ratio: 16 / 9;
		min-height: 385px;
		object-fit: contain; */
	}
}
@media screen and (max-width: 600px) {
	.review {
		margin-top:48px;
	}
	.review .reviewsVideo {
		flex-wrap: nowrap;
	}
	.reviewHeader {
		font-weight: 750;
		font-size: 20px;
		margin-bottom: 16px;

	}
}
