.teacher {
	margin-top: 60px;
}
.teacherHeader {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 32px;
	line-height: 156.5%;
	margin-bottom: 24px;
	/* identical to box height, or 50px */
	display: flex;
	align-items: center;
	text-align: center;
	font-feature-settings: "tnum" on, "lnum" on;

	/* Black */
	color: #212121;
}

.teacher .react-player__preview {
	/* border-radius: 8px; */
}
.teacher .teachersVideo {
	display: flex;
	flex-wrap: wrap;
	/* overflow-x: scroll; */
	overflow: hidden;
}
.teacher .teachersVideo div {
	/* margin-bottom: 2rem; */
	aspect-ratio: 16 / 9;
}
@media screen and (max-width: 600px) {
	
	.teacher .teachersVideo {
		flex-wrap: nowrap;
	}
	.teacherHeader {
		font-weight: 750;
		font-size: 20px;
		margin-bottom: 16px;
	}
	/* .teacher .teachersVideo div {
		margin-bottom: 2rem;
	} */
}
.teacher .stats {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	margin-top: 60px;
}
.teacher .stats .stateBox .logo {
	text-align: center;
}
.teacher .stats .stateBox .number {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 32px;
	line-height: 156.5%;

	/* identical to box height, or 50px */
	text-align: center;
	font-feature-settings: "tnum" on, "lnum" on;

	color: #626262;
	padding: 1rem 0 0.2rem;
}
.teacher .stats .stateBox .brief {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 156.5%;

	/* or 28px */
	text-align: center;

	/* Black */
	color: #212121;
}
@media only screen and (max-width: 600px) {
	.teacher {
		margin-top:48px;
	}
	.teacherHeader {
		font-weight: 750;
		font-size: 20px;
	}
	.teacher .stats {
		margin-top:48px;
	}
	.teacher .landing {
		padding: 5rem 0rem;
	}
	.teacher .stats .stateBox .number {
		font-weight: 750;
		font-size: 16px;
	}
	.teacher .stats .stateBox .brief {
		font-weight: 600;
		font-size: 12px;
	}
	/* .teacher .joinAd {
		font-weight: 750;
		font-size: 14px;
	} */
}
