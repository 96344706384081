.ThankYouWrapper {
    background: #FDF8F4;
    border-radius: 10px;
}

.modal-header {
    border-bottom: none !important;
}

.ThankYouTittle {
    font-family: 'Avenir LT Std';
    font-style: normal;
    font-weight: 750;
    font-size: 24px;
    line-height: 156.5%;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #000000;
    margin-top: 34px;
    margin-bottom: 16px;
}

.ThankYouDescription {
    font-family: 'Avenir LT Std';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 156.5%;
    color: #000000;
}